
:root {
  --bg-color: hsl(203, 8%, 20%);
  --fg-color: hsl(180, 2%, 90%);
  --fg-color-dimmed: hsl(206, 3%, 44%);
  --acc-color: hsl(353, 62%, 83%);
  --acc-color-dimmed: hsl(353, 12%, 49%);
  --acc-green: hsl(88, 28%, 60%);
  --a-color: hsl(218, 57%, 58%);
  --a-color-dimmed: hsl(218, 32%, 38%);
  --lighter-bg-color: hsl(203, 6%, 26%);
  --light-color: hsl(206, 3%, 44%);

  --border-width: 2px;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  /* margin: 0; */
  /* padding: 0; */
  /* border: 0; */
  /* font-size: 100%; */
  /* font: inherit; */
  /* vertical-align: baseline; */
}

*, *:after, *:before {
  /* box-sizing: border-box; */
}

body {
  /* line-height: 1; */
}

body {
  margin: 0;
  background-color: var(--bg-color);
  color: var(--fg-color);
}

a {
  color: var(--a-color);
  text-decoration: none;
  max-width: 20ch;
  text-overflow: ellipsis;
  max-width: 10em;
  overflow: hidden;
}

li.ingridient {
  list-style-type: none;
  margin: .2em .2em 0.2em 0.2em;
}

.recipesList > li {
  list-style-type: none;
  margin: 1em 0.2em 0.2em 0.2em;
}

li.note {
  /* list-style-type: none; */
  list-style-type: lao;
  margin: 0em .2em 0.2em 0.2em;
}

.note > p {
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}

.app {
  width: 75%;
  margin: 2rem auto 2rem auto;
}

.button {
  /* border-style: dashed; */
  border-radius: 2px;
  padding: 0 0.3em 0 0.3em;
  text-align: center;
  cursor: pointer;
}

.recipesList {
  font-size: large;
}

input {
}

hr {
  border-color: var(--acc-color-dimmed);
  margin: 0em 0em 1em 0em;
  /* max-width: 10em; */
}

textarea {
  width: 100%;
  min-height: 5em;
  height: 30vh;
  border-width: var(--border-width);
}

.editable {
  background: inherit;
  color: var(--fg-color);
  border-style: none;
  border-radius: 2px;
  border-color: var(--bg-color);
  border-style: dashed;
}

.ingridientsSection .editable {
  border-color: var(--lighter-bg-color);
}

.editable:hover {
  border-color: var(--fg-color-dimmed);
  border-style: dashed;
}

.details {
}

.titleRow {
  grid-template-columns: 3% 94% 3%;
  display: grid;
  align-items: center;
  color: var(--acc-color);
  font-size: larger;
  font-weight: 900;
}

.title {
  width: 100%;
  color: var(--acc-color);
  font-size: larger;
  font-weight: 900;
}

.url {
  color: var(--a-color);
  text-overflow: ellipsis;
  max-width: 10em;
  overflow: hidden;
}

.tempAndTime {
  display: grid;
  grid-template-columns: 7em calc(100% - 7em - 2 * var(--border-width));
  grid-template-rows: auto;
  margin-left: 1.6em; /* TODO this works and looks ok but feels wrong */
}

.header {
  font-size: larger;
  margin: 1.5rem 1em 0.2rem 0em;
  font-weight: bold;
}

.description {
  margin: 0;
  padding-left: 1.2em;
}

.ingridientsSections {
  display: flex;
  flex-wrap: wrap;
}

.ingridientsSections > * {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 550px;
  /* flex-basis: 33%; */
  flex-basis: 10rem;
  border-width: 2px;
  border-radius: 2px;
  border-style: solid;
  gap: 2em; /* TODO work it out */
}

.ingridientsSection {
  background-color: var(--lighter-bg-color);
  border-color: var(--bg-color);
  /* margin: 2px; */
}

.ingridientsSectionPlaceholder {
  color: var(--light-color);
  border-color: var(--light-color);
  border-style: dashed;
  /* margin: 4px; */
  min-height: 1em;
  justify-content: center;
  align-items: center;
  font-size: 3em;
}

.sectionTitle {
  display: grid;
  grid-template-columns: 84% 9% 7%;
  margin: 0.4em 0em 1em 1em;
  font-weight: bold;
}

.ingridientsList {
  padding: 0;
}

.ingridient {
  /* margin: 2px; */
  padding: 0em 1em 0em 1.2em;
  display: grid;
}

.ingridient.on {
  grid-template-columns: 10% 14% 73% 3%;
}

.ingridient.off {
  grid-template-columns: 8% 14% 78%;
}

.dimmed {
  color: var(--fg-color-dimmed);
}

.note {
}

.tags {
  display: flex;
  flex-wrap: wrap;
}

.tags > * {
  flex-shrink: 1;
  margin: 2px;
}

.tag {
  display: flex;
  border-radius: 4px;
  background: var(--a-color-dimmed);
  color: var(--fg-color);
  padding: 0.1em 0.4em 0.1em 0.4em;
}

.x {
  margin-left: 0.4em;
  color: var(--bg-color);
  cursor: pointer;
}
